import { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Box, ButtonBase, Divider, Drawer, Stack, AppBar, Container, Toolbar, IconButton, Select, MenuItem, Typography, Skeleton } from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

import logo from "../../images/logos/AI_Optimizer_Logo-01.png";

import { ROUTES, SIDEBAR_WIDTH } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";
import SideBarLink from "../data/SideBarLink";
import { planTypesText } from "../../functions/Misc";

const SideBar = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    return (
        <>
            <Drawer
                variant="permanent"
                sx={{
                    width: SIDEBAR_WIDTH,
                    display: {
                        xs: "none",
                        lg: "block"
                    }
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgb(28, 37, 54)',
                        color: 'common.white',
                        width: SIDEBAR_WIDTH,
                    }
                }}
            >
                <ButtonBase sx={{ py: 2.5, mx: "0 auto" }} onClick={() => navigate("/")}>
                    <Box component="img" display="block" mx="auto" sx={{ height: '55px' }} src={logo} alt='Romier Solutions Logo'></Box>
                </ButtonBase>

                <Divider />

                <Selector />

                <Divider />

                <Content setDrawer={setOpen} />

            </Drawer>

            <AppBar sx={{
                position: "static",
                display: {
                    xs: "flex",
                    lg: "none"
                }
            }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setOpen(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box component="img" display="block" mx="auto" sx={{
                            position: "absolute",
                            height: '90%',
                            left: 0,
                            right: 0,
                        }} src={logo} alt='Romier Solutions Logo'></Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                open={open}
                onClose={() => setOpen(false)}
                variant="temporary"
                sx={{
                    display: {
                        xs: "block",
                        lg: "none"
                    }
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'rgb(28, 37, 54)',
                        color: 'common.white',
                    }
                }}
            >

                <Selector />

                <Divider />

                <Content setDrawer={setOpen} />
            </Drawer>
        </>
    );
}

const Selector = () => {
    const { user, setUser } = useContext(UserContext);

    if (!user.performedRequest) {
        return (
            <>
                <Skeleton variant="text" sx={{ mt: 1.5, mx: 2.5, fontSize: '2.5rem' }} />
                <Skeleton sx={{ my: 1.5, mx: 2.5 }} variant="rounded" height={100} />
            </>
        )
    }

    return (
        <>
            <Typography variant="body1" sx={{ mt: 1.5, mx: 2.5, textAlign: "center" }}>
                {user.company.additional.name}
            </Typography>
            <Select
                value={user.selectedPlan}
                sx={{ mt: 1.5, mb: 2.5, mx: 2.5 }}
                disabled={user.plans.length <= 1}
                renderValue={(value) => {
                    const plan = user.plans.find(e => e._id === value)
                    if (!plan) return <></>;

                    return (
                        <Stack>
                            <Typography variant="body1">
                                {planTypesText(plan)}
                            </Typography>
                        </Stack>
                    )
                }}
                onChange={(e) => {
                    const value = e.target.value;

                    const _user = { ...user };
                    _user.selectedPlan = value;
                    setUser(_user);
                }}
            >
                {user.plans.map(e =>
                    <MenuItem key={e._id} value={e._id}>
                        {planTypesText(e)}
                    </MenuItem>
                )}
            </Select>
        </>
    )
}

export interface IContent {
    setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}
const Content = ({ setDrawer }: IContent) => {
    const { user } = useContext(UserContext);

    return (
        <Box sx={{ py: 2.5, px: 2.5, height: "100%" }} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <Stack
                component="ul"
                spacing={0.5}
                sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                }}
                flexGrow={1}
            >
                {
                    user.performedRequest
                        ?
                        ROUTES.filter(e => {
                            switch (e.type) {
                                case "index": return true;
                                case "signed-in": return user && user.token;
                                case "admin": return user && user.token && user.flags.some(e => e === "admin-readonly");
                                case "signed-out": return !user || !user.token;
                            }

                            return false;
                        }).map(e => <SideBarLink key={e.id} route={e} setDrawer={setDrawer} />)
                        :
                        <Skeleton sx={{ my: 2.5, mx: 2.5 }} variant="rounded" height={150} />
                }
            </Stack>

            <Stack
                component="ul"
                spacing={0.5}
                sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0
                }}
            >
                {
                    user.performedRequest
                        ?
                        ROUTES.filter(e => {
                            switch (e.type) {
                                case "legal": return true;
                                case "cookie": return true;
                            }

                            return false;
                        }).map(e => <SideBarLink key={e.id} route={e} setDrawer={setDrawer} />)
                        :
                        <Skeleton sx={{ my: 2.5, mx: 2.5 }} variant="rounded" height={100} />
                }
            </Stack>
        </Box>
    );
}

export default SideBar;